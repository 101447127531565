import React, {lazy, Suspense, useEffect, useState} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';
import Aos from 'aos';

const Auth = lazy(() => import('./components/pages/Auth'));
const Dashboard = lazy(() => import('./components/pages/Dashboard'));
const Campaign = lazy(() => import('./components/pages/Campaign'));


const App: React.FC = () => {

    const mqList = [window.matchMedia('(min-width: 1224px)'), window.matchMedia('(min-height: 768px)')];
    const [horizontal, setHorizontal] = useState(mqList[0].matches);
    const [vertical, setVertical] = useState(mqList[1].matches);

    useEffect(() => Aos.init({duration: 1500}), []);

    useEffect(() => {
        mqList[0].addEventListener('change', (e) => setHorizontal(e.matches));
        mqList[1].addEventListener('change', (e) => setVertical(e.matches));
        return () => {
            mqList[0].removeEventListener('change', (e) => setHorizontal(e.matches));
            mqList[1].removeEventListener('change', (e) => setVertical(e.matches));
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [horizontal, vertical]);

    function setDocHeight() {
        const basePercentMax = Math.max(window.innerHeight, window.innerWidth) / 100;
        const basePercentMin = Math.min(window.innerHeight, window.innerWidth) / 100;
        document.documentElement.style.setProperty('--vw', `${horizontal ? window.innerWidth / 100 : 12.24}px`);
        document.documentElement.style.setProperty('--vh', `${vertical ? window.innerHeight / 100 : 7.68}px`);
        document.documentElement.style.setProperty('--vmax', `${(horizontal || vertical) && basePercentMax > 12.25 ? basePercentMax : 12.24}px`);
        document.documentElement.style.setProperty('--vmin', `${(horizontal || vertical) && basePercentMin > 7.69 ? basePercentMin : 7.68}px`);
        document.documentElement.style.setProperty('--em', `${(horizontal || vertical) && basePercentMax > 12.25 ? basePercentMax * 0.5 + 6 : 12}px`);
    }

    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);

    setDocHeight();

    return (
        <Suspense fallback={<div/>}>
            <Switch>
                <Route exact path={'/'} children={<Auth/>}/>
                <Route exact path={'/dashboard'} children={<Dashboard/>}/>
                <Route exact path={'/campaign'} children={<Campaign/>}/>
            </Switch>
        </Suspense>
    );
}

export default App;
