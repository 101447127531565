import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

Sentry.init({
    dsn: "https://b066fba9d06d40b48b0ed89734a59b47@o915266.ingest.sentry.io/5882130",
    integrations: [new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.Fragment>
      <RecoilRoot>
          <Router history={history}>
            <App />
          </Router>
      </RecoilRoot>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
